import "./style.css";
import group_img from "../../../../assets/images/Group 28.png"

export default function EstiInControl() {
    return (
        <div className="esti_control_cont">
            <div className="container">
                <div className="container_inner d_flex align_items_center">
                    <img src={group_img} alt="pic"/>
                    <div className="d_flex fd_column justify_content_start text_left  esti_control_cont_b2">
                        <h2 className="fs_36 f_600">Ești în control</h2>
                        <div className="f_400 margin_top_16 fs_18">Bonamea.ro te ajută să găsești o bonă perfectă pentru
                            copilul tău, așa că tu poți sta
                            liniștit. <span className="f_600">Siguranța dumneavoastră este cea mai importantă pentru noi.</span>
                        </div>
                        <div className="margin_top_16 f_400 fs_18">
                            Implementăm măsuri stricte pentru a asigura un mediu sigur, inclusiv verificarea actului de
                            identitate, recenzii detaliate, verificări penale, mesagerie securizată și procese de plată
                            sigure.
                        </div>
                        <div className="margin_top_16 f_400 fs_18">
                            Prioritatea noastră este să vă oferim încredere și să vă menținem pe dumneavoastră și
                            familia dumneavoastră într-un mediu sigur.
                        </div>
                        <div className="margin_top_16 f_400 fs_18">
                            Reducem grija și aducem liniște sufletească! Cu profiluri clare, instrumente practice și
                            echipa noastră de asistență de încredere, eliminăm stresul asociat îngrijirii copiilor.
                        </div>
                        <div  className="margin_top_16 f_400 fs_18">
                            Descoperiți cu inteligență și eficiență o bona în care aveți încredere sau găsiți
                            rapid și cu ușurință o oportunitate de muncă potrivită.
                        </div>
                        <button className="button_full f_700 ">
                            Caută
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}