import {useState} from "react";
import car_icon from "../../../../assets/images/icons/car_icon.png";
import cook_icon from "../../../../assets/images/icons/chef_icon.png";
import labor_icon from "../../../../assets/images/icons/sewing-machine_icon.png";
import homework_icon from "../../../../assets/images/icons/book_icon.svg";
import tales_icon from "../../../../assets/images/icons/fairy_tales_icon.png";
import games_icon from "../../../../assets/images/icons/fairy_tales_icon.png";
import translation_icon from "../../../../assets/images/icons/translation_icon.png";


export default function Skills(){
    let [skills, setSkills] = useState([
        {
            id: 1,
            pic: car_icon,
            text: "Drives a car",
        },
        {
            id: 2,
            pic: cook_icon,
            text: "Cook food",
        },
        {
            id: 3,
            pic: labor_icon,
            text: "Manual labor",
        },
        {
            id: 4,
            pic: homework_icon,
            text: "Homework assistance",
        },
        {
            id: 5,
            pic: tales_icon,
            text: "Read fairy tales",
        },
        {
            id: 6,
            pic: games_icon,
            text: "Play games",
        },
        {
            id: 7,
            pic: translation_icon,
            text: "Knowledge of languages",
        },

    ])
    return (
        <div>
            <h3 className="text_left fs_18 f_600 margin_top_24">Skills</h3>
            <div className="skills_cont d_flex margin_top_12">
                {
                    skills.map((item,index) =>{
                        return (
                            <div className="skills_cont_item" key={item.id}>
                                <div
                                    className="skills_cont_item_inner d_flex fd_column justify_content_center align_items_center">
                                    <img src={item.pic} alt="driver_icon"/>
                                    <span className="fs_14 f_400">{item.text}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
