import "./style.css";
import filter_list_icon from "../../../../assets/images/icons/icon _filter list.png";
import search_icon from "../../../../assets/images/icons/icon _search.png";
import address_icon from "../../../../assets/images/icons/address_icon.png";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import close_icon from "../../../../assets/images/icons/close_icon.png";
import * as React from "react";
import RangerSlider from "./components/ranger_slider/RangerSlider";
import FindOnMap from "../find_on_map/FindOnMap";


export default function Filtration() {
    let [showFiltration, setShowFiltration] = useState(false)
    let [showSearch, setShowSearch]=useState(false)
    let [showMap, setShowMap]=useState(false)
    const [value, setValue] = useState(null)
    const [valueRange, setValueRange] = useState([
        {
            id: 1,
            name: "Experience",
            from: 1,
            text: "year",
            textFilter: 'year work',
            to: 20,
            number: 20,
            min: 1,
            max: 20,
        },
        {
            id: 2,
            name: "Age",
            from: 14,
            text: "",
            textFilter: 'year old',
            to: 35,
            number: 28,
            min: 14,
            max: 35,
        },
        {
            id: 3,
            name: "Pay",
            text: "RON (1 hour rate)",
            textFilter: " RON/hr",
            from: 5.00,
            to: 15000.00,
            number: 25,
            min: 5,
            max: 15000.00,
        },
    ])
    const [filteredList, setFilteredList] = useState([])
    const [searchText, setSearchText] = useState("")


    let tabToggle = (active) => {
        if (active === "Filtration"){
            setShowFiltration(true);
            setShowSearch(false);
            setShowMap(false)
        }
        if (active === "Search"){
            setShowSearch(true);
            setShowFiltration(false);
            setShowMap(false);

        }
        if (active === "Map"){
            setShowMap(true);
            setShowFiltration(false);
            setShowSearch(false);
        }
    }

    useEffect(() => {
        let time
        if (value) {
            console.log(value);
            time = setTimeout(() => {
                filteredList.push({
                    calculateValue: value.count,
                    text: value.text,
                })
                setFilteredList([...filteredList])
            }, 600)
        }
        return () => clearTimeout(time)
    }, [value])

    let valueLabelFormat = (value) => {
        const units = [""];
        let unitIndex = 0;
        let scaledValue = value;

        while (scaledValue >= 1 && unitIndex < units.length - 1) {
            unitIndex += 1;
            scaledValue /= 1;
        }
        return `${scaledValue} ${units[unitIndex]}`;
    }

    function calculateValue(valueRange) {
        return valueRange;
    }

    let handleClickDelete = (index) => {
        filteredList.splice(index, 1)
        setFilteredList([...filteredList])
        console.log(index)
    }

    let clearFilters = () => {
        setFilteredList([])
        console.log(filteredList, "rtyrt")
    }

    let handleChangeSearch = (e) => {
        setSearchText(e.target.value)
        console.log(searchText)
    }


    return (
        <div className="d_flex fd_column">
            <div className="filtration_cont d_flex align_items_center space_between">
                <button className="filter_btn bc_purple c_white f_600 fs_18"
                        onClick={()=>tabToggle("Filtration")}><img
                    src={filter_list_icon} alt="filter_icon"/>Filtration
                </button>
                <div className="search_block"  onClick={()=>tabToggle("Search")}>
                    <img src={search_icon} alt="search_icon"/>
                    <input type="text" placeholder="Search" className="fs_18"
                           onChange={handleChangeSearch}/>
                    <div className="text_left">{searchText}</div>
                </div>
                <div>
                    <button className="find_in_map_btn c_purple fs_18" onClick={()=>tabToggle("Map")}>
                        <img src={address_icon} alt="address_icon"/>
                        Find it on the map
                    </button>
                </div>

            </div>
            <div className="filtration_content">
                {
                    showFiltration  &&
                        <div className="">
                            <div className="d_flex">
                                <p className="text_left fs_24 f_600">Filters</p>
                                <button className="apply_btn fs_18 f_600">Apply</button>
                            </div>

                            <div className="d_flex fd_column justify_content_start  margin_top_32">
                                <div className="d_flex align_items_center space_between">
                                    {
                                        valueRange.map((item, index) => {
                                            return (
                                                <div className="range_b" key={item.id}>
                                                    <p className="fs_18 f_600 text_left">{item.name}</p>
                                                    <div className="ranger_cont d_flex fd_column margin_top_8">
                                                        <div className="d_flex space_between align_items_center">
                                                            <div>from {item.from} {item.text}</div>
                                                            <div>to {item.to}</div>
                                                        </div>
                                                        <RangerSlider valueLabelFormat={valueLabelFormat}
                                                                      calculateValue={calculateValue}
                                                                      handleChange={(event, newValue) => {
                                                                          setValue({
                                                                              count: newValue,
                                                                              text: item.textFilter
                                                                          })
                                                                      }}
                                                                      value={item.value}
                                                                      min={item.min} max={item.max}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="d_flex justify_content_start align_items_center filtred_items_b">
                                    <div className="d_flex justify_content_start align_items_center flex_wrap">
                                        {
                                            filteredList.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Typography id="non-linear-slider" gutterBottom
                                                                    className="d_flex align_items_center fs_14 f_400 ">
                                                            {valueLabelFormat(item.calculateValue)} {item.text}
                                                            <div onClick={() => handleClickDelete(index, item)}><img
                                                                src={close_icon}
                                                                alt="close_icon"
                                                                className="close_icon"/>
                                                            </div>
                                                        </Typography>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        value ?
                                            <div className="clear_filters_btn f_600 fs_14" onClick={clearFilters}>
                                                Clear filters
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                }
                {showMap  &&
                    <FindOnMap filteredList={filteredList} setFilteredList={setFilteredList}
                               valueLabelFormat={valueLabelFormat} handleClickDelete={handleClickDelete}/>
                }
            </div>
        </div>

    )
}
