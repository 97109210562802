import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as PropTypes from "prop-types";
import Home from "./pages/Home/Home";
import FindNannyPage from "./pages/FindNannyPage/FindNannyPage";
import GasesteOBona from "./pages/GaseșteOBona/GaseșteOBona";



Routes.propTypes = {children: PropTypes.node};


function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
              <Route path="/gasește_o_bona" element={<GasesteOBona/>}/>
              <Route path="/find_nanny" element={<FindNannyPage/>}/>
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
