import "../style.css";
import arrow_down from "../../../../../assets/images/icons/arrow_down_icon_black.png";
import {useState} from "react";

export default function ParentsQuestions(){
    let [show, setShow] = useState(true);
    let [showActive, setShowActive] = useState(0);
    let [accordionItems, setAccordionItems] = useState([
        {
            id: 1,
            accordionTitle: "Lorem ipsum dolor sit?",
            accordionDesc: `Lorem ipsum dolor sit amet consectetur. Cursus facilisi morbi morbi 
            sociis gravida lacinia mauris interdum. Purus commodo auctor facilisi interdum elementum ac sed. 
            Viverra eu massa donec nam penatibus bibendum. Odio facilisi ut elit ipsum ultrices nibh pellentesque.`,
        },
        {
            id: 2,
            accordionTitle: "Lorem ipsum dolor sit?",
            accordionDesc: `Lorem ipsum dolor sit amet consectetur. Cursus facilisi morbi morbi 
            sociis gravida lacinia mauris interdum. Purus commodo auctor facilisi interdum elementum ac sed. 
            Viverra eu massa donec nam penatibus bibendum. Odio facilisi ut elit ipsum ultrices nibh pellentesque.`,
        },
        {
            id: 3,
            accordionTitle: "Lorem ipsum dolor sit?",
            accordionDesc: `Lorem ipsum dolor sit amet consectetur. Cursus facilisi morbi morbi 
            sociis gravida lacinia mauris interdum. Purus commodo auctor facilisi interdum elementum ac sed. 
            Viverra eu massa donec nam penatibus bibendum. Odio facilisi ut elit ipsum ultrices nibh pellentesque.`,
        },
        {
            id: 4,
            accordionTitle: "Lorem ipsum dolor sit?",
            accordionDesc: `Lorem ipsum dolor sit amet consectetur. Cursus facilisi morbi morbi 
            sociis gravida lacinia mauris interdum. Purus commodo auctor facilisi interdum elementum ac sed. 
            Viverra eu massa donec nam penatibus bibendum. Odio facilisi ut elit ipsum ultrices nibh pellentesque.`,
        },
    ])
    return (
        <div className={`accordion`}>
            {accordionItems.map((item, index) => {
                console.log(index, "omomom")
                return (
                    <div onClick={() => setShowActive(index)} key={item.id}>
                        <div  className={showActive === index ? "accordion_block_active margin_top_32" :"accordion_block margin_top_32"} onClick={() => setShow(!show)}>
                            <div className="d_flex space_between align_items_center">
                                <div
                                    className={"accordion_title f-700 fs-18"}>{item.accordionTitle}</div>
                                {show ?
                                    <img src={arrow_down} alt="" style={{transform:"rotate(180deg)"}}/>
                                    :
                                    <img src={arrow_down} alt=""/>
                                }
                            </div>
                            {showActive === index ?
                                <div className={"accordion_desc_block"}>
                                    {show ?
                                        <div
                                            className="accordion_description text_left margin_top_16">{item.accordionDesc}</div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}