import "./style.css";
import {NavLink, useNavigate} from "react-router-dom";
import logo from "../../assets/images/Logo.png";
import arrow_down_icon from "../../assets/images/icons/arrow_down_icon_black.png";

export default function Header(){
    let navigate = useNavigate()
    return (
        <header className="bc-white header_login_container">
            <div className="container">
                <div className="container_inner">
                    <div
                        className="header_container_inner d_flex align_items_center space_between">
                        <div onClick={() => navigate("/")}><img src={logo} alt="logo" className="logo"/></div>
                        <div
                            className="d_flex align_items_center space_between header_login_content f-500">
                            <NavLink
                                className={({isActive}) => isActive ? 'header_btn_active f_600 c_purple fs_18'  : 'header_btn f_600 fs_18'}
                                to="/gasește_o_bona">Gaseste o bona</NavLink>
                            <NavLink
                                className={({isActive}) => isActive ? 'header_btn_active f_600 c_purple fs_18'  : 'header_btn f_600  fs_18'}
                                to="/gasește_o_slujba">Gaseste o slujba</NavLink>
                            <div className="header_btn f_600 fs_18 d_flex align_items_center">
                                Ajutor <img src={arrow_down_icon} alt="arrow_down"/>
                            </div>
                        </div>
                        <div className="d_flex align_items_center log_reg_block">
                            <button className="login_btn fs_18 c_purple f_600"
                                    onClick={() => navigate("/login")}>Autentificare
                            </button>
                            <button className="signUp_btn fs_18 c_purple f_600"
                                    onClick={() => navigate("/signup")}>Inregistreaza-te
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}