import {useState} from "react";
import car_icon from "../../../../assets/images/icons/car_icon.png";
import cook_icon from "../../../../assets/images/icons/chef_icon.png";
import labor_icon from "../../../../assets/images/icons/sewing-machine_icon.png";
import homework_icon from "../../../../assets/images/icons/book_icon.svg";
import tales_icon from "../../../../assets/images/icons/fairy_tales_icon.png";
import games_icon from "../../../../assets/images/icons/fairy_tales_icon.png";
import translation_icon from "../../../../assets/images/icons/translation_icon.png";
import  driver_license_icon from "../../../../assets/images/icons/driver_license_icon.png";
import child_icon from "../../../../assets/images/icons/child_icon.png";
import cigarette_icon from "../../../../assets/images/icons/cigarrete_icon.png";
import pets_icon from "../../../../assets/images/icons/pets_icon.svg";


export default function GeneralInfo(){
let [generalInfo, setGeneralInfo]=useState([
    {
        id:1,
        pic:driver_license_icon,
        question:"Driver’s license",
        answer:"Yes",
    },
    {
        id:2,
        pic:child_icon,
        question:"Has children",
        answer:"No",
    },
    {
        id:3,
        pic:car_icon,
        question:"Car",
        answer:"Yes",
    },
    {
        id:4,
        pic:cigarette_icon,
        question:"Car",
        answer:"Yes",
    },
    {
        id:5,
        pic:pets_icon,
        question:"Pet",
        answer:"No",
    },
    {
        id:6,
        pic:translation_icon,
        question:"Languages | know",
        answer:"English, French, Spain",
    },
])
    return (
        <div>
            <h3 className="text_left fs_18 f_600 margin_top_24">General info</h3>
            <div className="general_info_cont margin_top_12">
                <div className="general_info_block d_grid grid_columns_2fr">
                    {
                        generalInfo.map((item,index)=>{
                        return (
                            <div className="general_info_block_item d_flex space_between" key={item.id}>
                                <div className="d_flex align_items_center">
                                    <img src={item.pic} alt="driver_license_icon"/>
                                    <span className="fs_14 f_400">{item.question}</span>
                                </div>
                                <span className="fs_14 f_400">{item.answer}</span>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}