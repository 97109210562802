import GoogleMapB from "../google_map/GoogleMap";
import Typography from "@mui/material/Typography";
import close_icon from "../../../../assets/images/icons/close_icon.png";
import * as React from "react";
import map_pic from "../../../../assets/images/map.png";


export default function FindOnMap({filteredList, setFilteredList, valueLabelFormat, handleClickDelete}){
    return (
        <div>
            <div className="d_flex justify_content_start align_items_center flex_wrap">
                {
                    filteredList.map((item, index) => {
                        return (
                            <div key={index}>
                                <Typography id="non-linear-slider" gutterBottom
                                            className="d_flex align_items_center fs_14 f_400 ">
                                    {valueLabelFormat(item.calculateValue)} {item.text}
                                    <div onClick={() => handleClickDelete(index, item)}><img
                                        src={close_icon}
                                        alt="close_icon"
                                        className="close_icon"/>
                                    </div>
                                </Typography>
                            </div>
                        )
                    })
                }
            </div>
            <GoogleMapB />
        </div>
    )
}