import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import star_pic from "../../../../assets/images/icons/star_full_yellow.png";
import "./style.css";


export default function HomeBlock4Swiper({reviews, setReviews}){

    return (
        <div>
            <Swiper
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
                effect={"fade"}
                // spaceBetween={50}
                slidesPerView={4}
                rewind={true}
                navigation={true}
                modules={[Navigation]}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                className="question_swiper_slider"
            >
                <div className="margin_top_32">
                    {
                        reviews.map((item,index)=>{
                            return (
                                <SwiperSlide>
                                    <div className="d_flex fd_column align_items_start questionSwiperSlide" key={item.id}>
                                        <div className="questionSwiperSlide_main_block text_left">
                                            <p className="fs_18 f_600 margin_top_32">{item.name}</p>
                                            <div className="margin_top_8">
                                                <div className="d_flex">
                                                    <img src={star_pic} alt=""/>
                                                    <img src={star_pic} alt=""/>
                                                    <img src={star_pic} alt=""/>
                                                    <img src={star_pic} alt=""/>
                                                    <img src={star_pic} alt=""/>
                                                </div>
                                                {/*{*/}
                                                {/*    item.starCount * <img src={star_pic} alt=""/>*/}
                                                {/*}*/}
                                            </div>
                                            <div className="margin_top_16">
                                                {item.opinion}
                                            </div>
                                            <div className="margin_top_16" style={{color:"#5D5567"}}>
                                                {item.date}
                                            </div>


                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </div>

            </Swiper>
        </div>
    )
}