import "./style.css";
import HomeBlock3Swiper from "./HomeBlock3Swiper";
import {useState} from "react";
import employer_pic from "../../../../assets/images/babysitter+pic2.png";
import certificated_pic from "../../../../assets/images/cerificated_pic.png";

export default function HomeBlock3() {
    let [bestBabySitter, setBestBabysitter]=useState([
        {
            id:1,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",

            cash:"20,00",
        },
        {
            id:2,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:3,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:4,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:5,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:6,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
    ])

    return (
        <div className="home_b3">
            <div className="container">
                <div className="container_inner">
                    <h2 className="text_center">Cei mai buni angajați din 2023</h2>
                    <div style={{position:"relative"}}>
                        <HomeBlock3Swiper bestBabySitter={bestBabySitter} setBestBabysitter={setBestBabysitter} />
                    </div>

                </div>
            </div>
        </div>
    )
}