import "./style.css";
import {useState} from "react";
import CalendarComponent from "./components/calendar/Calendar";

export default function Availability() {
    let monthsData = [
        "January", "February", "March", "April", "May",
        "June", "July", "August", "September", "October", "November", "December"
    ]
    const [monthActive, setMonthActive] = useState(null)
    return (
        <div>
            <h3 className="text_left fs_18 f_600 margin_top_24">Availability</h3>
            <div className="availability_cont margin_top_12">
                <div className="d_flex align_items_center space_between months_b ">
                    {
                        monthsData.map((item, index) => {
                            return (
                                <div key={index}
                                     className={monthActive === index ? "months_b_item_active fs_14 f_400" : "months_b_item fs_14 f_400"}
                                     onClick={() => setMonthActive(index)}>{item}</div>
                            )
                        })
                    }
                </div>
                <div className="d_flex align_items_center margin_top_24">
                    <div>
                        <CalendarComponent/>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
}