import "./style.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import home_pic from "../../assets/images/Home_pic.png";
import search_icon from "../../assets/images/icons/search_icon.png";
import connect_icon from "../../assets/images/icons/connect_icon.png";
import calendar_icon from "../../assets/images/icons/calendar_icon.png";
import EstiInControl from "./components/esti_control/EstiInControl";
import HomeBlock3 from "./components/home_block_3/HomeBlock3";
import HomeBlock5 from "./components/home_block_5/HomeBlock5";
import HomeBlock4 from "./components/home_block_4/HomeBlock4";
import {useNavigate} from "react-router-dom";


export default function Home() {
    let navigate=useNavigate()

    return (
        <div className="main">
            <div>
                <div className="back_pic_b">
                    <Header/>
                    <div className="home_block1 d_flex justify_content_start fd_column">
                        <h1 className="fs_46 f_600 text_left">Găsește-ți bona sau locul de muncă ideal</h1>
                        <div className="text_left margin_top_8 fs_18">Fie că vrei să găsești o bonă de încredere sau să
                            descoperi jobul perfect ca și bonă,
                            Bonamea.ro îți simplifică total modul în care alegi.
                        </div>
                        <div
                            className="d_flex justify_content_start align_items_center home_block1_btns_b margin_top_32">
                            <button className="Găsește_o_bonă_btn c_white f_600 bc_purple"
                                    onClick={()=>navigate("/gasește_o_bona")}>Găsește o bonă</button>
                            <button className="Gaseste_o_slujba_btn c_purple f_600">Gaseste o slujba</button>
                        </div>
                    </div>
                    <div className="home_block2 d_flex align_items_center justify_content_center">
                        <div className="container">
                            <div className="container_inner d_flex align_items_center">
                                <div
                                    className="home_block2_item d_flex fd_column justify_content_center align_items_center">
                                    <img src={search_icon} alt="search_icon"/>
                                    <p className="margin_top_8 f_600 fs_24">Cautare</p>
                                    <div className="margin_top_8 fs_16 f_400">Adaptați filtrarea la nevoile
                                        dumneavoastră și examinați profilurile cu
                                        atenție.
                                    </div>
                                </div>
                                <div
                                    className="home_block2_item d_flex fd_column justify_content_center align_items_center">
                                    <img src={connect_icon} alt="connect_icon"/>
                                    <p className="margin_top_8 f_600 fs_24">Conectează-te</p>
                                    <div className="margin_top_8 fs_16 f_400">Inițiați comunicare, evaluați cu atenție
                                        utilizatorii și planificați o întâlnire introductivă.
                                    </div>
                                </div>
                                <div
                                    className="home_block2_item d_flex fd_column justify_content_center align_items_center">
                                    <img src={calendar_icon} alt="calendar_icon"/>
                                    <p className="margin_top_8 f_600 fs_24">Rezerva</p>
                                    <div className="margin_top_8 fs_16 f_400">Planificați întâlniri pentru servicii de
                                        îngrijire a copiilor, efectuați plăți și descărcați chitanțele ușor prin
                                        platforma noastră.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <EstiInControl/>
                <HomeBlock3/>
                <HomeBlock4/>
                <HomeBlock5/>
            </div>

            <Footer/>
        </div>
    )
}