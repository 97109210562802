import "./style.css";
import {useState} from "react";
import ParentsQuestions from "./components/ParentsQuestions";
import NannyQuestions from "./components/NannyQuestions";

export default function HomeBlock5(){
    let [parentsQuestions,setParentsQuestions] = useState(true);
    let [nannyQuestions, setNannyQuestions] = useState(false);

    let tabToggler = (active) => {
        if (active === "întrebările părinților"){
            setParentsQuestions(true);
            setNannyQuestions(false);
        }
        if (active === "întrebări bonă"){
            setNannyQuestions(true);
            setParentsQuestions(false);

        }
    }
    return (
        <div className="home_block5_cont">
            <div className="container">
                <div className="container_inner">
                   <h2 className="text_center f_600">Intrebari si raspunsuri</h2>

                    <div className="d_flex tabs_block margin_top_32">
                        <button  className={parentsQuestions ? "tab_active fs_24 f_600" : "tab fs_24 f_600"}
                                 onClick={()=>tabToggler("întrebările părinților")}
                        >întrebările părinților
                        </button>
                        <button  className={nannyQuestions ? "tab_active  fs_24 f_600" : "tab  fs_24 f_600"}
                                 onClick={()=>tabToggler("întrebări bonă")}>
                            întrebări bonă
                        </button>
                    </div>
                    <div id="întrebările părinților" className={parentsQuestions ? "showTab": "hideTab"}>
                        <ParentsQuestions/>
                    </div>
                    <div id="întrebări bonă" className={nannyQuestions ? "dhowTab" : "hideTab"}>
                        <NannyQuestions/>
                    </div>

                </div>
            </div>
        </div>

    )
}