import "./style.css";
import {useEffect, useState} from "react";
import employer_pic from "../../../../assets/images/babysitter+pic2.png";
import star_pic from "../../../../assets/images/star_full.png";
import location_icon from "../../../../assets/images/icons/location_address.png";
import childrenCount_pic from "../../../../assets/images/icons/children_count_pic.png";
import cash_icon from "../../../../assets/images/icons/credit_card_icon.png";
import certificated_pic from "../../../../assets/images/cerificated_pic.png";
import {useNavigate} from "react-router-dom";

export default function OurSpecialists(){
    let navigate=useNavigate()
    let [specialists, setSpecialists]=useState([
        {
            id:1,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:2,
            pic:employer_pic,
            name:"Angela Smith",
            age:"28",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:3,
            pic:employer_pic,
            name:"Angela Smith",
            age:"35",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:4,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:5,
            pic:employer_pic,
            name:"Angela Smith",
            age:"21",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
        {
            id:6,
            pic:employer_pic,
            name:"Angela Smith",
            age:"23",
            certificatedPic:certificated_pic,
            starCount:"5",
            location:"Lorem ipsum dolor, California, USA",
            childrenCount:"1",
            cash:"20,00",
        },
    ])
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [curPage, setCurPage] = useState(1);
    const [error, setError] = useState("");

    const getData = async (url) => {
        const res = await fetch(url);
        return res.json();
    };

    useEffect(() => {
        const url = `https://picsum.photos/v2/list?page=${curPage}&limit=8`;
        setLoading(true);
        getData(url)
            .then((res) => {
                setHasMore(res.length > 0);
                setSpecialists((prev) => [...prev, ...res]);
                setError("");
            })
            .catch((err) => {
                // set the error msg
                setError("Something went wrong, Please try again later");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [curPage]);

    const loadMoreOnClick = () => {
        // prevent click if the state is loading
        if (loading) return;
        setCurPage((prev) => prev + 1);
    };



    return (
        <div className="our_specialists_cont">
            <h2 className="text_center f_600">Our Specialists</h2>
            <div className="margin_top_32 d_grid grid_gab_32 grid_columns_4fr">
                {
                    specialists.map((item,index)=>{
                        return (
                            <div className="d_flex fd_column align_items_center employerSwiperSlide" key={item.id} onClick={()=>navigate("/find_nanny")}>
                                <img src={item.pic} alt="pic" className="babySicker_pic"/>
                                <div className="employerSwiperSlide_main_block">
                                    <div className="d_flex  space_between">
                                        <div className="d_flex fd_column justify_content_start align_items_start">
                                            <p className="fs_18 f_700">{item.name}</p>
                                            <p className="fs_16 f_400" style={{marginTop:"4px"}}><span>{item.age}</span> de ani </p>
                                        </div>
                                        <img src={item.certificatedPic} alt="certificated_pic" className="certificate_pic"/>
                                    </div>
                                    <div className="d_flex align_items_center space_between employerSwiperSlide_infoB">
                                        <div className="d_flex  align_items_center employerSwiperSlide_infoB_item">
                                            <img src={childrenCount_pic} alt=""/>
                                            <p className="fs_14 f_400">Mai puțin de <span>{item.childrenCount}</span> an </p>
                                        </div>
                                        <div className="d_flex align_items_center  employerSwiperSlide_infoB_item">
                                            <img src={cash_icon} alt=""/>
                                            <p className="fs_14 f_400"><span>{item.cash}</span> RON/hr </p>
                                        </div>
                                    </div>
                                    <div className="d_flex align_items_center employerSwiperSlide_location_b">
                                        <img src={location_icon} alt=""/>
                                        <p className="fs_16 f_400">{item.location}</p>
                                    </div>
                                    <div className="margin_top_8">
                                        <div className="d_flex justify_content_center">
                                            <img src={star_pic} alt=""/>
                                            <img src={star_pic} alt=""/>
                                            <img src={star_pic} alt=""/>
                                            <img src={star_pic} alt=""/>
                                            <img src={star_pic} alt=""/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {error && <p className="load_more_error">{error}</p>}
            {hasMore && (
            <div className="margin_top_32" onClick={loadMoreOnClick}>
                <button className="button_border f_600 fs_18"> {loading ? "Loading..." : "Load More"}</button>
            </div>
            )}
        </div>
    )
}