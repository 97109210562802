import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import "./style.css";


export default function RangerSlider({calculateValue, valueLabelFormat, handleChange, value, min, max}) {
    return (
        <Box className="ranger_cont d_flex fd_column margin_top_8">
            <Slider
                value={value}
                min={min}
                step={1}
                max={max}
                scale={calculateValue}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
            />
        </Box>
    );
}