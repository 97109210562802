import "./style.css";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import Filtration from "./components/filtration/Filtration";
import OurSpecialists from "./components/our_specialists/OurSpecialists";
import gen_pic from "../../assets/images/gen_pic.png";



export default function GasesteOBona(){
    return (
        <div className="main">
            <div>
                <Header/>
                <div className="GăseșteOBonă_gen_pic_b">
                    <img src={gen_pic} alt="pic" className="GăseșteOBonă_gen_pic"/>
                    <div className="f_600 c_white">THESE ARE OUR NANNIES WE ARE PROUD OF</div>
                </div>
                <div className="container">
                    <Filtration/>
                    <OurSpecialists/>
                </div>

            </div>

          <Footer/>
        </div>
    )
}
