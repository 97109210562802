import "./style.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import childrenCount_pic from "../../assets/images/icons/children_count_pic.png";
import cash_icon from "../../assets/images/icons/credit_card_icon.png";
import location_icon from "../../assets/images/icons/location_address.png";
import star_pic from "../../assets/images/star_full.png";
import checked_icon from "../../assets/images/icons/checked_icon.png";
import cancel_icon from "../../assets/images/icons/cancel_icon.png";
import pdf_icon from "../../assets/images/icons/pdf_icon.png";
import employer_pic from "../../assets/images/babysitter+pic2.png";
import certificated_pic from "../../assets/images/cerificated_pic.png";
import Skills from "./components/skills/Skills";
import GeneralInfo from "./components/general_info/GeneralInfo";
import Availability from "./components/availability/Availability";

const data = {
    id: 1,
    pic: employer_pic,
    name: "Angela Smith",
    age: "23",
    certificatedPic: certificated_pic,
    starCount: "5",
    location: "Lorem ipsum dolor, California, USA",
    childrenCount: "1",
    cash: "20,00",
}


export default function FindNannyPage() {

    return (
        <div className="main">
            <div>
                <Header/>
                <div className="container">
                    <div className="specialist_page_cont d_flex align_items_start">
                        <div className="d_flex fd_column align_items_center specialist_page_cont_b1">
                            <img src={data.pic} alt="pic" className="babySicker_pic"/>
                            <div className="specialist_page_cont_b1_main_block">
                                <span className="fs_14 f_400">Online Now</span>
                                <div className="d_flex  space_between margin_top_12">
                                    <div className="d_flex fd_column justify_content_start align_items_start">
                                        <p className="fs_18 f_700">{data.name}</p>
                                        <p className="fs_16 f_400" style={{marginTop: "4px"}}><span>{data.age}</span> de
                                            ani </p>
                                    </div>
                                    <img src={data.certificatedPic} alt="certificated_pic" className="certificate_pic"/>
                                </div>
                                <div className="d_flex align_items_center space_between specialist_page_cont_b1_infoB">
                                    <div className="d_flex  align_items_center employerSwiperSlide_infoB_item">
                                        <img src={childrenCount_pic} alt=""/>
                                        <p className="fs_14 f_400">Mai puțin de <span>{data.childrenCount}</span> an
                                        </p>
                                    </div>
                                    <div className="d_flex align_items_center  specialist_page_cont_b1_infoB_item">
                                        <img src={cash_icon} alt=""/>
                                        <p className="fs_14 f_400"><span>{data.cash}</span> RON/hr </p>
                                    </div>
                                </div>
                                <div className="d_flex align_items_center specialist_page_cont_b1_location_b">
                                    <img src={location_icon} alt=""/>
                                    <p className="fs_16 f_400">{data.location}</p>
                                </div>
                                <div className="margin_top_8">
                                    <div className="d_flex justify_content_center">
                                        <img src={star_pic} alt=""/>
                                        <img src={star_pic} alt=""/>
                                        <img src={star_pic} alt=""/>
                                        <img src={star_pic} alt=""/>
                                        <img src={star_pic} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="specialist_page_cont_b2">
                            <h3 className="text_left fs_18 f_600">About me</h3>
                            <div className="fs_16 f_400 text_left margin_top_12">Lorem ipsum dolor sit amet consectetur.
                                Cursus
                                facilisi morbi
                                morbi sociis gravida
                                lacinia mauris interdum. Purus commodo auctor facilisi interdum elementum ac sed.
                                Viverra eu massa donec nam penatibus bibendum.
                            </div>
                            <h3 className="text_left fs_18 f_600 margin_top_24">Documentation</h3>
                            <div className="d_flex fd_column documentation_block_cont margin_top_12">
                                <div className="d_flex documentation_block">
                                    <div className="d_flex documentation_block_item">
                                        <img src={checked_icon} alt="checked_icon" className="checked_icon"/>
                                        <div className="d_flex fd_column align_items_start">
                                            <p className="fs_16 f_600">Identity document</p>
                                            <div className="f_400 fs_14">Angel has successfully provided an ID.</div>
                                            <span className="fs_14 f_600">Find out more.</span>
                                        </div>
                                    </div>
                                    <div className="d_flex documentation_block_item">
                                        <img src={cancel_icon} alt="cancel_icon" className="checked_icon"/>
                                        <div className="d_flex fd_column align_items_start">
                                            <p className="fs_16 f_600">Criminal record</p>
                                            <div className="f_400 fs_14">Angel has no Criminal Record verified by
                                                Bonamea.
                                            </div>
                                            <span className="fs_14 f_600">Learn more.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d_flex documentation_block2 margin_top_12">
                                    <div className="d_flex fd_column documentation_block_item">
                                        <img src={pdf_icon} alt="pdf_icon" className="pdf_icon"/>
                                        <div className="d_flex fs_16 f_600">
                                            Medical record
                                        </div>
                                    </div>
                                    <div className="d_flex fd fd_column documentation_block_item">
                                        <img src={pdf_icon} alt="pdf_icon" className="pdf_icon"/>
                                        <div className="d_flex fs_16 f_600">
                                            Certification
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <GeneralInfo/>
                            <Skills/>
                            <Availability/>
                        </div>
                    </div>
                </div>

            </div>

            <Footer/>
        </div>
    )
}