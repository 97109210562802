import "./HomeBlock3";
import {useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import employer_pic from "../../../../assets/images/babysitter_pic.png";
import star_pic from "../../../../assets/images/star_full.png";
import location_icon from  "../../../../assets/images/icons/location_address.png";
import childrenCount_pic from "../../../../assets/images/icons/children_count_pic.png";
import  age_icon from "../../../../assets/images/icons/age_icon.png";
import cash_icon from "../../../../assets/images/icons/credit_card_icon.png";


export default function HomeBlock3Swiper({bestBabySitter,setBestBabysitter}){

return (
    <div>
        <Swiper
            breakpoints={{
                576: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
            }}
            effect={"fade"}
            // spaceBetween={50}
            slidesPerView={4}
            rewind={true}
            navigation={true}
            modules={[Navigation]}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            className="employer_swiper_slider"
        >
            <div className="margin_top_32">
                {
                    bestBabySitter.map((item,index)=>{
                        return (
                            <SwiperSlide>
                                <div className="d_flex fd_column align_items_center employerSwiperSlide" key={item.id}>
                                        <img src={item.pic} alt="pic" className="babySicker_pic"/>
                                    <div className="employerSwiperSlide_main_block">
                                        <div className="d_flex  space_between">
                                            <div className="d_flex fd_column justify_content_start align_items_start">
                                                <p className="fs_18 f_700">{item.name}</p>
                                                <p className="fs_16 f_400" style={{marginTop:"4px"}}><span>{item.age}</span> de ani </p>
                                            </div>
                                            <img src={item.certificatedPic} alt="certificated_pic" className="certificate_pic"/>
                                        </div>
                                        <div className="d_flex align_items_center space_between employerSwiperSlide_infoB">
                                            <div className="d_flex  align_items_center employerSwiperSlide_infoB_item">
                                                <img src={childrenCount_pic} alt=""/>
                                                <p className="fs_14 f_400">Mai puțin de <span>{item.childrenCount}</span> an </p>
                                            </div>
                                            <div className="d_flex align_items_center  employerSwiperSlide_infoB_item">
                                                <img src={cash_icon} alt=""/>
                                                <p className="fs_14 f_400"><span>{item.cash}</span> RON/hr </p>
                                            </div>
                                        </div>
                                        <div className="d_flex align_items_center employerSwiperSlide_location_b">
                                            <img src={location_icon} alt=""/>
                                            <p className="fs_16 f_400">{item.location}</p>
                                        </div>
                                        <div className="margin_top_8">
                                            <div className="d_flex justify_content_center">
                                                <img src={star_pic} alt=""/>
                                                <img src={star_pic} alt=""/>
                                                <img src={star_pic} alt=""/>
                                                <img src={star_pic} alt=""/>
                                                <img src={star_pic} alt=""/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </div>

        </Swiper>
    </div>
)
}