import "./style.css";
import {useState} from "react";
import employer_pic from "../../../../assets/images/babysitter_pic.png";
import HomeBlock3Swiper from "../home_block_3/HomeBlock3Swiper";
import HomeBlock4Swiper from "./HomeBlock4Swipper";

export default function HomeBlock4() {
    let [reviews, setReviews] = useState([
        {
            id: 1,
            name: "Florentina",
            starCount: "5",
            opinion: "Lorem ipsum dolor sit amet consectetur. " +
                "Cursus facilisi morbi morbi sociis gravida lacinia mauris interdum." +
                " Purus commodo auctor facilisi interdum elementum ac sed. Viverra eu" +
                " massa donec nam penatibus bibendum. ",
          date:"13 Martie 2023",
        },
        {
            id: 2,
            name: "Florentina",
            starCount: "5",
            opinion: "Lorem ipsum dolor sit amet consectetur. " +
                "Cursus facilisi morbi morbi sociis gravida lacinia mauris interdum." +
                " Purus commodo auctor facilisi interdum elementum ac sed. Viverra eu" +
                " massa donec nam penatibus bibendum. ",
            date:"13 Martie 2023",
        },
        {
            id: 3,
            name: "Florentina",
            starCount: "5",
            opinion: "Lorem ipsum dolor sit amet consectetur. " +
                "Cursus facilisi morbi morbi sociis gravida lacinia mauris interdum." +
                " Purus commodo auctor facilisi interdum elementum ac sed. Viverra eu" +
                " massa donec nam penatibus bibendum. ",
            date:"13 Martie 2023",
        },
        {
            id: 4,
            name: "Florentina",
            starCount: "5",
            opinion: "Lorem ipsum dolor sit amet consectetur. " +
                "Cursus facilisi morbi morbi sociis gravida lacinia mauris interdum." +
                " Purus commodo auctor facilisi interdum elementum ac sed. Viverra eu" +
                " massa donec nam penatibus bibendum. ",
            date:"13 Martie 2023",
        },
        {
            id: 5,
            name: "Florentina",
            starCount: "5",
            opinion: "Lorem ipsum dolor sit amet consectetur. " +
                "Cursus facilisi morbi morbi sociis gravida lacinia mauris interdum." +
                " Purus commodo auctor facilisi interdum elementum ac sed. Viverra eu" +
                " massa donec nam penatibus bibendum. ",
            date:"13 Martie 2023",
        },
        {
            id: 6,
            name: "Florentina",
            starCount: "5",
            opinion: "Lorem ipsum dolor sit amet consectetur. " +
                "Cursus facilisi morbi morbi sociis gravida lacinia mauris interdum." +
                " Purus commodo auctor facilisi interdum elementum ac sed. Viverra eu" +
                " massa donec nam penatibus bibendum. ",
            date:"13 Martie 2023",
        },
    ])
    return (
        <div className="homeBlock4_cont">
            <div className="container">
                <div className="container_inner">
                    <h2 className="text_center">Recenzii despre noi</h2>
                    <div style={{position:"relative"}}>
                    <HomeBlock4Swiper reviews={reviews} setReviews={setReviews} />
                    </div>
                </div>
            </div>
        </div>
    )
}