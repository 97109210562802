import "./style.css";
import {NavLink} from "react-router-dom";
import facebook_icon from "../../assets/images/icons/facebook_icon.png";
import instagram_icon from "../../assets/images/icons/instagram_icon.png";



export default function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="container_inner d_flex space_between align_items_center">
                        <div className="d_flex align_items_center space_between">
                            <NavLink
                                className={({isActive}) => isActive ? 'header_btn_active f_400 c_purple fs_14'  : 'header_btn f_400 fs_14'}
                                to="/privacy">Privacy</NavLink>
                            <NavLink
                                className={({isActive}) => isActive ? 'header_btn_active f_400 c_purple fs_14'  : 'header_btn  fs_14'}
                                to="/terms">Terms & Conditions</NavLink>
                        </div>
                        <div className="d_flex fs_14 f_400">
                            © 2023. All rights reserved.
                        </div>
                    <div className="d_flex social_block">
                        <a href="https://www.facebook.com" target="_blank"><img src={facebook_icon} alt="facebook_icon"/></a>
                        <a href="https://www.instagram.com" target="_blank"><img src={instagram_icon} alt="instagram_icon"/></a>
                    </div>
                </div>
            </div>
        </div>
    )
}